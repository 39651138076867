import React from "react";
interface BenefitsLeftContainerProps {
  color: string;
}
const BenefitsLeftContainer: React.FC<BenefitsLeftContainerProps> = (props) => (
  <svg
    width="897"
    height="178"
    viewBox="0 0 897 178"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M744.5 9H46.4067C45.2431 9 44.1845 9.67282 43.6905 10.7263L3.63319 96.1497C3.23435 97.0003 3.25695 97.9885 3.69425 98.8199L42.6566 172.897C43.1753 173.883 44.1732 174.5 45.2873 174.5C347.731 174.5 796.043 174.5 818 174.5C840 174.5 893.5 141 893.5 92.5C893.5 44 856.5 9 810.5 9"
      stroke={props.color}
      strokeWidth="6"
    />
    <circle
      cx="8.5"
      cy="8.5"
      r="8.5"
      transform="matrix(-1 0 0 1 819 0)"
      fill={props.color}
    />
    <circle
      cx="8.5"
      cy="8.5"
      r="8.5"
      transform="matrix(-1 0 0 1 754 0)"
      fill={props.color}
    />
  </svg>
);

export default BenefitsLeftContainer;
