import React, { useEffect, useState } from "react";
import monitor from "../../../../assets/PungoIcons/MonitorAnimation.gif";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const AnimationSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [text, setText] = useState(t("presentationSection.txt3"));
  const [textsES] = useState(["PODER", "BENEFICIO", "IMPACTO"]);
  const [textsEN] = useState(["IMPACT", "CHANGE", "BENEFITS"]);
  const [index, setIndex] = useState(0);

  
  useEffect(() => {
    const nextIndex = () => {
      if (index===2){
        setIndex(0);
      }else{
        setIndex(index+1)
      }
    }

    const intervalId = setInterval(() => {
      nextIndex();
      i18n.language === "es" ? setText(textsES[index]) : setText(textsEN[index]);
    }, 1500);

    return () => clearInterval(intervalId);
  }, [textsES, textsEN, i18n.language, index]);

  return (
    <>
      <section className={styles.containerAnimation} id="start">
        <PungoSectionCard title={" "} distribution="block">
          <div className={styles.txt}></div>
        </PungoSectionCard>
        <PungoSectionCard title=" " distribution="block">
          <div className={styles.textBlock}>
            <div className={styles.banner}>
              <div className={styles.mainTxt}>
                {`${t("presentationSection.txt0")}`}
                &nbsp;
                <span className={styles.highlight}>{text}</span>
                &nbsp;
                {`${t("presentationSection.txt6")}`}
              </div>
              <div className={styles.txt}>{`${t("presentationSection.txt7")}`}</div>
            </div>

            <div className={styles.flexImages}>
              <img className={styles.gif} src={monitor} alt="Monitor animation" />
            </div>
          </div>
        </PungoSectionCard>
      </section>
    </>
  );
};

export default AnimationSection;
