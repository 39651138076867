export const isEmail = (value: string): boolean => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(value);
};


export const isPhone = (value: string): boolean => {
  const regex = /^\+?[0-9]{0,4}\s*[0-9]{4,15}$/;
  return regex.test(value);
};
