import React from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

interface PungoClickableProps {
  link?: string;
  children: React.ReactNode;
  classNames?: string;
  onClick?: () => void;
}

const PungoClickable: React.FC<PungoClickableProps> = (props) => {
  const { children, link, classNames, onClick } = props;

  const getClassNames = () => classnames(classNames, styles.container);

  return (
    <a
      onClick={onClick}
      href={link}
      target="_blank"
      rel="noreferrer"
      className={getClassNames()}
    >
      {children}
    </a>
  );
};

export default PungoClickable;

