import React from "react";
interface BenefitsRightContainerProps {
  color: string;
}
const BenefitsRightContainer: React.FC<BenefitsRightContainerProps> = (props) => (
  <svg width="897" height="178" viewBox="0 0 897 178" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M 85,10 A 1 1 0 1 0 85 170 H 855" stroke={props.color} strokeWidth="4" />
    {/* <polygon points="775,170 855,170 895,90 855,10 775,10" fill={props.color} /> */}
    <path d="M 855,170 L 895,90 L 855,10" stroke={props.color} strokeWidth="4" />
    <polygon points="775,160 845,160 880,90 845,20 775,20" fill={props.color} />
    <path d="M 855,10 H150" stroke={props.color} strokeWidth="4" />
    <circle cx="85" cy="10" r="8.5" fill={props.color} /> 
    <circle cx="150" cy="10" r="8.5" fill={props.color} /> 
  </svg>
);

export default BenefitsRightContainer;
