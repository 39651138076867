import React from "react";
import PungoButton from "../PungoButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Menu, MenuItem } from "@mui/material";
import styles from "./index.module.scss";

interface PungoMenuProps {
  options?: React.ReactNode[];
  icon?: React.ReactNode;
}

const PungoMenu: React.FC<PungoMenuProps> = (props) => {
  const { options, icon = <MenuIcon className={styles.icon} /> } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.container}>
      <PungoButton onClick={handleClick} type="icon" icon={icon} />
      <Menu
        id="basic-menu"
        open={open}
        classes={{ paper: styles.menu }}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options?.map((option) => (
          <MenuItem key={`${option}`}>{option}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PungoMenu;
