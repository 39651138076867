import React from "react";
import { useTranslation, Trans } from "react-i18next";
import PungoProductCard from "../../../../pungo-ui/PungoProductCard";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import AucaIcon from "../../../../assets/PungoIcons/AucaIcon.svg";
import AucaAnimation from "../../../../assets/PungoIcons/AucaAnimation.svg";
import AstroIcon from "../../../../assets/PungoIcons/AstroIcon.svg";
import AstroAnimation from "../../../../assets/PungoIcons/AstroAnimation.svg";

import styles from "./index.module.scss";

const aucaURL: string = process.env.REACT_APP_AUCA_URL || "";
const astroURL: string = process.env.REACT_APP_ASTRO_URL || "";


const ProductsSection: React.FC = () => {
  const { t } = useTranslation();

  const astroCard = (
    <PungoProductCard
      title={AstroIcon}
      subtitle={<Trans>{t("productsContent.astro.subtitle")}</Trans>}
      key="astro"
      image={AstroAnimation}
      link={astroURL}
      features={[t("productsContent.astro.features.two"), t("productsContent.astro.features.three")]}
    />
  );

  const aucaCard = (
    <PungoProductCard
      title={AucaIcon}
      subtitle={<Trans>{t("productsContent.auca.subtitle")}</Trans>}
      key="auca"
      image={AucaAnimation}
      link={aucaURL}
      features={[t("productsContent.auca.features.one"), t("productsContent.auca.features.two")]}
    />
  );

  const getProducts = () => (
    <div className={styles.container}>
      {astroCard}
      {aucaCard}
    </div>
  );

  return (
    <PungoSectionCard title={t("productsContent.title")} description={`${t("productsContent.description")}`} id="products">
      {getProducts()}
    </PungoSectionCard>
  );
};

export default ProductsSection;
