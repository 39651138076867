import React from "react";
import PungoBenefitsCard from "../../../../pungo-ui/PungoBenefitsCard";
import PungoBenefitsCardMobile from "../../../../pungo-ui/PungoBenefitsCardMobile";
import PungoCustomizedCarousel from "../../../../pungo-ui/PungoCustomizedCarousel";
import { useTranslation } from "react-i18next";
import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import styles from "./index.module.scss";
import colors from "../../../../styles/export.module.scss";
import letterEx from "../../../../assets/PungoIcons/Ex.svg";
import letterIn from "../../../../assets/PungoIcons/In.svg";
import letterPe from "../../../../assets/PungoIcons/Pe.svg";
import letterSo from "../../../../assets/PungoIcons/So.svg";
import letterCu from "../../../../assets/PungoIcons/Cu.svg";

const BenefitsSection: React.FC = () => {
  const { t } = useTranslation();

  const desktopView = useMediaQuery(
    json2mq({
      minWidth: 965,
    })
  );

  const processData = [
    {
      id: "benefit01",
      header: t("BenefitsSection.one.title"),
      description: t("BenefitsSection.one.paragraph"),
      color: colors.green2,
      image: letterEx,
      imagePosition: "left" as const,
    },
    {
      id: "benefit02",
      header: t("BenefitsSection.two.title"),
      description: t("BenefitsSection.two.paragraph"),
      color: colors.green4,
      image: letterIn,
      imagePosition: "right" as const,
    },
    {
      id: "benefit03",
      header: t("BenefitsSection.three.title"),
      description: t("BenefitsSection.three.paragraph"),
      color: colors.blue1,
      image: letterPe,
      imagePosition: "left" as const,
    },
    {
      id: "benefit04",
      header: t("BenefitsSection.four.title"),
      description: t("BenefitsSection.four.paragraph"),
      color: colors.blue3,
      image: letterSo,
      imagePosition: "right" as const,
    }
  ];

  const getProcessSection = () =>
    processData.map(({ id, header, description, color, image, imagePosition }) => (
      <div key={id} className={styles.cardContainer}>
        <PungoBenefitsCard key={header} description={description} color={color} title={header} image={image} imagePosition={imagePosition} />
      </div>
    ));

    const getProcessSectionMobile = () =>
      processData.map(({ id, header, description, color, image, imagePosition }) => (
        <div key={id} className={styles.cardContainer}>
          <PungoBenefitsCardMobile key={header} description={description} color={color} title={header} image={image} imagePosition={imagePosition} />
        </div>
      ));

  return (
    <PungoSectionCard title={" "} id="ourProcess">
      <div className={styles.container}>
        {desktopView ? (
          getProcessSection()
        ) : (
          <div className={styles.carouselContainer}>
            <PungoCustomizedCarousel slides={getProcessSectionMobile()} height={310} />
          </div>
        )}
      </div>
    </PungoSectionCard>
  );
};

export default BenefitsSection;
