import React from "react";

import PungoClickable from "../PungoClickable";
import styles from "./index.module.scss";

interface PungoTeamMemberCardProps {
  photo: string;
  name: string;
  description: string;
  link: string;
  position?: string;
}

const PungoTeamMemberCard: React.FC<PungoTeamMemberCardProps> = (props) => {
  const { name, link, description, position, photo } = props;
  return (
    <div className={styles.container}>
      <img src={photo} alt="Team member" className={styles.photo} />
      <div className={styles.position}>{position}</div>
      <div className={styles.name}>{name}</div>
      <div className={styles.description}>{description}</div>
      <PungoClickable link={link} classNames={styles.linkedInButton}>
        in
      </PungoClickable>
    </div>
  );
};

export default PungoTeamMemberCard;
