import React from "react";
interface BenefitsMobileContainerProps {
  color: string;
}
const BenefitsMobileContainer: React.FC<BenefitsMobileContainerProps> = (
  props
) => (
  <svg
    width="400"
    height="600"
    viewBox="0 0 400 600"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 270,110 H350 V 420 L 200,450 L 50,420 V 110 H 130 " stroke={props.color} strokeWidth="4" />
    <circle r="7" cx="130" cy="110" fill={props.color} />
    <circle r="7" cx="270" cy="110" fill={props.color} />
    
  </svg>
);

export default BenefitsMobileContainer;
