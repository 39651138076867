import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { changeLanguage } from "i18next";

import PungoMenu from "../../pungo-ui/PungoMenu";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { FormControl, MenuItem, Select } from "@mui/material";
import json2mq from "json2mq";

import PSLogo from "../../assets/PungoLogo.svg";
import PSShortLogo from "../../assets/PungoIcons/ShortIcon.svg";

import breakpoints from "../../styles/export.module.scss";
import styles from "./index.module.scss";

const Navbar: React.FC = () => {
  const { t, i18n } = useTranslation();

  const desktopView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.xlg,
    })
  );
  const mobileView = useMediaQuery(
    json2mq({
      minWidth: breakpoints.lg,
    })
  );
  const navbarButtons = [
    {
      section: "team",
      label: `${t("sectionLabel.team")}`,
    },
    {
      section: "services",
      label: `${t("sectionLabel.services")}`,
    },
    {
      section: "products",
      label: `${t("sectionLabel.products")}`,
    },
    {
      section: "partners",
      label: `${t("sectionLabel.partners")}`,
    },
    {
      section: "contactUs",
      label: `${t("sectionLabel.contactUs")}`,
    },
  ];

  const getNavbarButtons = () =>
    navbarButtons.map(({ section, label }) => (
      <Link activeClass={styles.activeButton} smooth spy to={section} offset={-120} className={styles.button} key={label}>
        {label}
      </Link>
    ));

  const getLanguageSelect = () => (
    <FormControl size="small">
      <Select value={i18n.language} onChange={(e) => changeLanguage(e.target.value)} className={styles.selectLanguage}>
        <MenuItem value="es">ES</MenuItem>
        <MenuItem value="en">EN</MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.navbarContainer}>
        {desktopView && (
          <Link spy to="start" offset={-150} smooth>
            <img src={PSLogo} alt="Pungo logo" className={styles.image} />
          </Link>
        )}
        {!desktopView && (
          <Link smooth spy to="start" offset={-150}>
            <img src={PSShortLogo} alt="Pungo logo" className={styles.shortImage} />
          </Link>
        )}
        <div className={styles.space} />
        <div className={styles.actionsContainer}>
          {!mobileView && <PungoMenu options={getNavbarButtons()} />}
          {mobileView && getNavbarButtons()}
          <div className={styles.languageSelect}>{getLanguageSelect()}</div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
