import React from "react";

import classnames from "classnames";
import styles from "./index.module.scss";

interface PungoSectionCardProps {
  title: string;
  description?: string;
  children: React.ReactNode;
  id?: string;
  distribution?: string;
}

const PungoSectionCard: React.FC<PungoSectionCardProps> = (props) => {
  const { title, description, children, id, distribution = "flex" } = props;
  const getClassNamesContainer = () =>
    classnames(styles.container, {
      [styles.flexDistribution]: distribution === "flex",
      [styles.blockDistribution]: distribution === "block",
    });

  const getClassNamesChildren = () =>
    classnames(styles.children, {
      [styles.flexDistribution]: distribution === "flex",
      [styles.blockDistribution]: distribution === "block",
    });

    const getClassNamesDescription = () =>
      classnames(styles.description, {
        [styles.flexDistribution]: distribution === "flex",
        [styles.blockDistribution]: distribution === "block",
      });
  

  return (
    <section className={getClassNamesContainer()} id={id}>
      {title && <div className={styles.header}>{title}</div>}
      {description && <div className={getClassNamesDescription()}>{description}</div>}
      <div className={getClassNamesChildren()}>{children}</div>
    </section>
  );
};

export default PungoSectionCard;
