import React from "react";
import colors from "../../styles/export.module.scss";

import classnames from "classnames";
import styles from "./index.module.scss";

interface PungoServiceCardProps {
  title: string;
  children?: React.ReactNode;
  image: string;
  color?: string;
  className?: string;
}

const PungoServiceCard: React.FC<PungoServiceCardProps> = (props) => {
  const { color = colors.green4, title, image, children, className } = props;
  const getClassnames = () => classnames(styles.container, className);

  return (
    <div className={getClassnames()} style={{ borderColor: `${color}` }}>
      <img src={image} className={styles.image} alt="serviceLogo" />
      <div className={styles.title} style={{ color }}>
        {title}
      </div>
      {children}
    </div>
  );
};

export default PungoServiceCard;
