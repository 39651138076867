import React from "react";
import { I18nextProvider } from "react-i18next";
import instance from "./translations/i18n";
import PungoPage from "./components/PungoPage";

import "./App.css";

const App = () => (
  <div className="App">
    <I18nextProvider i18n={instance}>
      <PungoPage />
    </I18nextProvider>
  </div>
);

export default App;
