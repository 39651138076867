import React from 'react';

import { Check } from '@material-ui/icons';

import styles from './index.module.scss';

interface BulletedSectionProps {
  color: string;
  text: string;
}

const BulletedSection: React.FC<BulletedSectionProps> = (props) => {
  const { text, color } = props;
  return (
    <div className={styles.section}>
      <Check style={{ color: `${color}` }} />
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default BulletedSection;
