import React from "react";
import styles from "./index.module.scss";

import BenefitsMobileContainer from "./benefitsMobileContainer";

interface PungoBenefitsCardProps {
  image?: string;
  title: string;
  description?: string;
  id?: string;
  color: string;
  imagePosition?: string;
}

const PungoBenefitsCard2Mobile: React.FC<PungoBenefitsCardProps> = (props) => {
  const { image, title, description, id, color } = props;
  
  return (
    <section className={styles.container} id={id}>
      <div className={styles.content}>
        <div className={styles.borderContainer}>
          <BenefitsMobileContainer color={color} />
        </div>
        <img src={image} alt="" className={styles.image} />
        <div className={styles.textContainer}>
          <div className={styles.title} style={{ color: color }}>
            {title}
          </div>
          {description && <div className={styles.description}>{description}</div>}
        </div>
      </div>
    </section>
  );
};

export default PungoBenefitsCard2Mobile;
