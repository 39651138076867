import React from "react";
import PungoClickable from "../PungoClickable";
import PungoButton from "../PungoButton";
import { useTranslation, Trans } from "react-i18next";

import styles from "./index.module.scss";

interface PungoProductCardProps {
  title: string;
  subtitle?: React.ReactNode;
  image: string;
  link: string;
  features: string[];
}

const PungoProductCard: React.FC<PungoProductCardProps> = (props) => {
  const { title, subtitle, image, link, features } = props;
  const { t } = useTranslation();
  const getFeatures = (data: any) => {
    return features.map((txt: any, index: any) => {
      return <li key={index}> {data[index]} </li>;
    });
  };
  const handleSubmit = () => {};

  return (
    <>
      <PungoClickable link={link}>
        <div className={styles.container}>
          <img src={title} alt="Product Logo" className={styles.title} />
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          <div className={styles.content}>
            <img src={image} alt="Animation Logo" className={styles.icon} />
            {/* <div className={styles.description}>{description}</div> */}
          </div>
          <ul className={styles.listItems}>{getFeatures(features)}</ul>
          <PungoButton label={`${t("productsContent.button")}`} onClick={handleSubmit} color="tertiary" classNames={styles.submitButtonOver} />
        </div>
      </PungoClickable>
    </>
  );
};

export default PungoProductCard;
