import React from "react";
interface BenefitsRightContainerProps {
  color: string;
}
const BenefitsRightContainer: React.FC<BenefitsRightContainerProps> = (
  props
) => (
  <svg
    width="897"
    height="178"
    viewBox="0 0 897 178"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M152.5 9H850.593C851.757 9 852.816 9.67282 853.31 10.7263L893.367 96.1497C893.766 97.0003 893.743 97.9885 893.306 98.8199L854.343 172.897C853.825 173.883 852.827 174.5 851.713 174.5C549.269 174.5 100.957 174.5 79 174.5C57.0001 174.5 3.5 141 3.5 92.5C3.5 44 40.4999 9 86.5 9"
      stroke={props.color}
      strokeWidth="6"
    />
    <circle cx="86.5" cy="8.5" r="8.5" fill={props.color} />
    <circle cx="151.5" cy="8.5" r="8.5" fill={props.color} />
  </svg>
);

export default BenefitsRightContainer;
