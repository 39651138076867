import React from "react";
interface BenefitsLeftContainerProps {
  color: string;
}
const BenefitsLeftContainer: React.FC<BenefitsLeftContainerProps> = (props) => (
  <svg width="897" height="178" viewBox="0 0 897 178" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M 805,10 A 1 1 0 0 1 805 170 H 45" stroke={props.color} strokeWidth="4" />
    <path d="M 45,10 H 745" stroke={props.color} strokeWidth="4" />
    <polygon points="55,20 125,20 125,160 55,160 20,90" fill={props.color} />
    <path d="M 45,10 L 5, 90 L 45,170" stroke={props.color} strokeWidth="4" />
    <circle cx="810" cy="10" r="8.5"  fill={props.color} />
    <circle cx="740" cy="10" r="8.5"  fill={props.color} />
  </svg>
);

export default BenefitsLeftContainer;
