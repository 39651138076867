import React from "react";
import { useTranslation } from "react-i18next";

import PungoSectionCard from "../../../../pungo-ui/PungoSectionCard";
import Socio1 from "../../../../assets/PungoIcons/Socio1.svg";
import Socio2 from "../../../../assets/PungoIcons/Socio2.svg";
import Socio3 from "../../../../assets/PungoIcons/Socio3.svg";
import Socio4 from "../../../../assets/PungoIcons/Socio4.svg";

import styles from "./index.module.scss";

const PartnersSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PungoSectionCard
      title={t("sectionLabel.partnersExtendLabel")}
      id="partners"
    >
      <div className={styles.container}>
        <img src={Socio1} alt="Emprende Ecuador Logo" className={styles.logo} />
        <img src={Socio2} alt="UTPL Logo" className={styles.logo} />
        <img src={Socio3} alt="VFA Logo" className={styles.logo} />
        <img src={Socio4} alt="MEC Logo" className={styles.logo} />
      </div>
    </PungoSectionCard>
  );
};

export default PartnersSection;
