import React from "react";
interface BenefitsMobileContainerProps {
  color: string;
}
const BenefitsMobileContainer: React.FC<BenefitsMobileContainerProps> = (
  props
) => (
  <svg
    width="380"
    height="400"
    viewBox="0 0 380 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M240,35 H 320 V 190 L 190,250 L 60,190 V 35 H 140" stroke={props.color} strokeWidth="4" />
    <circle r="10" cx="245" cy="35" fill={props.color}/>
    <circle r="10" cx="135" cy="35" fill={props.color}/>

   
  </svg>
);

export default BenefitsMobileContainer;
